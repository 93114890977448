import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="PayCaptain integration - Rotaready"
          description="An intelligent integration that helps you pay employees in just a few clicks."
          url="integrations/paycaptain"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="PayCaptain and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">An intelligent integration that helps you pay employees in just a few clicks.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">Not only does this integration help you ensure accurate payroll, it removes the need for double entry of data making it a big timesaver for your payroll team.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://www.paycaptain.com" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit PayCaptain" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <GatsbyImage
                  image={data.heroImage.childImageSharp.gatsbyImageData}
                  alt="Screenshots of Rotaready and PayCaptain"
                  style={{ maxWidth: '450px' }}
                />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Guarantee payroll accuracy" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="The integration between Rotaready and PayCaptain enables employee hours, holiday and tronc to be automatically pulled from Rotaready into PayCaptain." />
              </TextBlock>

              <TextBlock>
                <Text size="xl">
                  This information is then used to run <Link style="color:inherit" to="/payroll">payroll</Link>, so you can be sure your employees are always paid accurately.
                </Text>
              </TextBlock>
              <TextBlock>
                <H3 uistyle="brand160" text="Ready to switch on the integration?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About PayCaptain" />
              </TextBlock>

              <TextBlock>
                <Text size="xl">
                  Meet PayCaptain... a complete SaaS <Link style="color:inherit" to="/payroll">Payroll</Link> and Financial Wellbeing solution, with a fully managed service. HMRC Recognised. FCA Registered. And the world's first Certified B Corporation <Link style="color:inherit" to="/payroll">Payroll</Link> Solution!
                </Text>
              </TextBlock>

              <TextBlock>
                <Text size="xl">
                  Working with innovative companies that understand the importance of good financial wellbeing in their workforce, PayCaptain offers a an optimised, modern and flexible <Link style="color:inherit" to="/payroll">payroll</Link> process.
                </Text>
              </TextBlock>

              <TextBlock>
                <Text size="xl">
                  Using the latest technology and thinking to make processing <Link style="color:inherit" to="/payroll">payroll</Link> and pensions as simple and easy as possible, this modern <Link style="color:inherit" to="/payroll">payroll</Link> solution improves and simplifies the whole <Link style="color:inherit" to="/payroll">payroll</Link> experience; saving employer's valuable time.
                </Text>
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="And for the employee the process of being paid is a consistently seamless experience. Companies using PayCaptain can give their employees the power to take control of their pay, savings and charitable donations." />
              </TextBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about PayCaptain"
                  render={props => <a {...props} href="https://www.paycaptain.com" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/paycaptain/hero-desktop.webp" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
